import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import StyledDisplayText from './StyledDisplayText';
//import Dropzone from 'react-dropzone'; // Use dropzone for file upload drag-and-drop
import './App.css';

// {
//   /* The following line can be included in your src/index.js or App.js file */
// }
import 'bootstrap/dist/css/bootstrap.min.css';
// import TreeToolsLogoWhite from './Treetools-logo_RGB_WHITE.png'


const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");

function App() {
  const [email, setEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [isRobot, setIsRobot] = useState(true);
  // const logoStyle = {
  //   position: 'absolute',
  //   top: '10px', // Adjust this value to control the distance from the top
  //   right: '10px', // Adjust this value to control the distance from the right
  //   width: '300px', // Adjust the width as needed
  //   height: 'auto', // Maintain the aspect ratio
  // };

  const handleRecaptchaChange = async (value) => {
    // The value parameter will contain the CAPTCHA response token.
    console.log("reCAPTCHA value:", value);
    // const data = {
    //   secret: "6LdzTEQoAAAAAF1gY67CLT7yQaNOq-iTImx6LGQe", 
    //   response: value
    // };

    // const endpoint = `https://www.google.com/recaptcha/api/siteverify`;
    // const response = await fetch(endpoint, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(data)
    // });

    const requestBody = { captchavalue: value };

    const response = await fetch("/api/VerifyCaptcha", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const result = await response.json();
    console.log(result);
    if(result.success===true) {
      console.log("VerifyCaptcha is true! Enable upload button...")
      setIsRobot(false);
    }
    else {
      setIsRobot(true);
    }
  };

  async function addJob(email, filename) {

    const data = {
      Email: email,
      Filename: filename,
      Status: "UPLOADED"
    };

    const endpoint = `/data-api/rest/Jobs/`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    console.table(result.value);
  }


  async function blobUploadFile(file, filename, url, container, sasKey) {
    // Send the data...
    var blobName = filename; 
    var login = `${url}/${container}/${blobName}?${sasKey}`;
    var blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());

    const fileSize = file.size;  // ev.totalBytes not guaranteed!!!

    const uploadOptions = {
      onProgress: (ev) => {
        const progress = Math.round((ev.loadedBytes / fileSize) * 100);
        console.log("Progress:" + ev.loadedBytes + " / " + fileSize + " (" + progress + "%)");
        setUploadProgress(progress);
      },
    };

    const uploadBlobResponse = await blockBlobClient.uploadData(file, uploadOptions);
    console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
    console.log("Upload block blob response : ",uploadBlobResponse._response.status);
  }

  async function blobWriteTextFile(text, filename, url, container, sasKey) {
    // ... and a YAML file with customer details
    var blobName = filename;
    var login = `${url}/${container}/${blobName}?${sasKey}`;
    var blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());
    const uploadBlobResponse = await blockBlobClient.upload(text, text.length);
    console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
    console.log("Upload block blob response : ",uploadBlobResponse._response.status);
  }

  function buildBlobName(file) {
      var filename = file.name.substring(0, file.name.lastIndexOf('.'));
      var ext = file.name.substring(file.name.lastIndexOf('.'));
      return filename + '_' + Math.random().toString(16).slice(2) + ext;
  }

  async function onFileUpload(e) {
    if (!email) {
      alert('Please enter an email address.');
      return;
    }
    if (selectedFile) {
      console.log("File size is :" + selectedFile.size);
      if(selectedFile.size>1024*1024*1024) {
        alert('Your file is too big - maximum size is 1GB!');
        return;
      }
      setUploading(true);
      console.log(selectedFile.name);
      var destFileName = "00_IncomingData/"+buildBlobName(selectedFile);
      const {url, sasKey} = await( await fetch("/api/message")).json();
      await blobUploadFile(selectedFile, destFileName, url, "images", sasKey);
      await blobWriteTextFile("Email: " + email + "\nFilename: " + selectedFile.name, destFileName + ".yaml", url, "images", sasKey);
      await addJob(email, destFileName);  //  removed since it blocks email if DB is asleep - insert moved to processing server script
      
      const requestBody = { mailto: email, uploadfile: selectedFile.name };

      await fetch("/api/SendEmailOnUpload", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      setUploading(false);
      setUploadProgress(0);
    } else {
      alert('Please select a file to upload.');
    }
  };


  return (  
    <Container className="vh-100 px-5" fluid={true}>
    {/* <Row>
      <Col md={3}></Col>
      <Col md={6}></Col>
      <Col md={3}>
        <div className="d-flex justify-content-center align-items-center">
          <img src={TreeToolsLogoWhite} alt="TreeTools Logo" style={logoStyle}/>
        </div>
      </Col>
    </Row> */}
    <Row>
      <Col>  
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="col-12">Enter your email address:</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select the zip file containing your LAZ file and spatial data:</Form.Label>
            <Form.Control type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
          </Form.Group>
          <div className="d-flex justify-content-center align-items-center">
            <ReCAPTCHA sitekey="6LdzTEQoAAAAAL1EDbRnhubg_ygg6tJwR2Fd6xNA" onChange={handleRecaptchaChange} />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button className="btn-lg" variant="success" onClick={onFileUpload}  disabled={uploading || isRobot} style={{ margin: '20px' }}>{uploading ? 'Uploading...' : 'Upload'}</Button>{' '}
          </div>
          {uploading && (
            <div className="progress-bar">
              <div className="filler" style={{ width: `${uploadProgress}%` }} />
            </div>
          )}
        </Form>
        </div>
      </Col>
    </Row>
    <Row>
      <Col></Col>
      <Col md={8}>
         {/* <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '30vh' }}> */}
         {/* <div className="d-flex justify-content-center align-items-center"> */}
         <div>
          <StyledDisplayText
            // content="<p><span class='custom-format'></p>"
            content="<p></p>"
          />
         </div>
      </Col>   
      <Col></Col>
    </Row>
    </Container>
  );
}

export default App;


// import React from 'react';
// import StyledDisplayText from './StyledDisplayText';

// function App() {
//   return (
//     <div className="App">
//       <h1>Styled Display Text Example</h1>
//       <StyledDisplayText text="This is a read-only text." />
//     </div>
//   );
// }

// export default App;

